/* line 13, scss/80-themes/Saunders/70-modules/agentspokenlanguages/module.scss */
.agent-details__icon {
	color: rgb(var(--color-global-primary));
}

/* line 18, scss/80-themes/Saunders/70-modules/agentspokenlanguages/module.scss */
.agent-details__spoken-languages .list-inline__item, .broker-details__service-areas .list-inline__item {
	margin-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agent-details__spoken-languages .list-inline__item, .is-rtl .broker-details__service-areas .list-inline__item {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agent-details__spoken-languages .list-inline__item, .is-rtl .broker-details__service-areas .list-inline__item {
		margin-right: 0;
	}
}

/* line 23, scss/80-themes/Saunders/70-modules/agentspokenlanguages/module.scss */
.m-agent-spokenlanguages {
	text-align: justify;
}

/* line 26, scss/80-themes/Saunders/70-modules/agentspokenlanguages/module.scss */
.m-agent-spokenlanguages .agent-details__spoken-languages {
	margin-bottom: 20px;
}

/* line 32, scss/80-themes/Saunders/70-modules/agentspokenlanguages/module.scss */
.agentdetails--wrapper .m-agent-spokenlanguages {
	padding-left: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agentdetails--wrapper .m-agent-spokenlanguages {
	padding-left: initial;
	padding-right: 20px;
}

/* line 35, scss/80-themes/Saunders/70-modules/agentspokenlanguages/module.scss */
.agentdetails--wrapper .m-agent-spokenlanguages .agent-details__spoken-languages {
	margin-bottom: 15px;
}

/* line 42, scss/80-themes/Saunders/70-modules/agentspokenlanguages/module.scss */
.agentdetails--wrapper .agent-details__spoken-languages .list-inline__item,
.agentdetails--wrapper .broker-details__service-areas .list-inline__item {
	margin-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agentdetails--wrapper .agent-details__spoken-languages .list-inline__item, .is-rtl
.agentdetails--wrapper .broker-details__service-areas .list-inline__item {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agentdetails--wrapper .agent-details__spoken-languages .list-inline__item, .is-rtl
	.agentdetails--wrapper .broker-details__service-areas .list-inline__item {
		margin-right: 0;
	}
}

/*# sourceMappingURL=../../../../../true */